import {IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {IWixStyleParams} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {
  BiParams,
  createUouBiMiddlewareWithBiParams,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {decodeInstance} from '../../commons/selectors/instance'
import {ErrorMonitor, initSentry} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/i18n'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, isSSR} from '../../commons/utils/wix-code-api'
import {
  applyMembershipDiscount,
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from '../actions/checkout'
import {toggleAgreePolicies, openPolicyModal} from '../actions/policies'
import {getCheckoutOptions} from '../actions/checkout-options'
import {publishComponentSettings, updateStyleParams} from '../actions/component'
import {applyCoupon, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers, shareEvent} from '../actions/event'
import {clearInvoice} from '../actions/invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {addLoginListener, ensureLoginForMembersOnly, openMembersModal, promptLogin} from '../actions/members'
import {
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openTicketsDetailsModal,
  openTimeExpiredModal,
} from '../actions/modals'
import {
  handleExternalRedirect,
  handleWithoutRegistrationRedirect,
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
} from '../actions/navigation'
import {downloadTickets} from '../actions/order-success'
import {
  applyPlan,
  getPlanList,
  getPurchasedPlanList,
  openMembershipPicker,
  setPaidPlansEnabled,
} from '../actions/paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from '../actions/payment'
import {placeOrder, updateOrder} from '../actions/placed-order'
import {registrationButtonClicked} from '../actions/registration'
import {cancelReservation, reserveTickets} from '../actions/reservation'
import {
  changeMemberRsvp,
  getMemberRsvp,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  updateRsvp,
  submitRsvpStep,
  handleNextRsvpStep,
  editRsvpStep,
  changeResponse,
} from '../actions/rsvp'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {updateSettings} from '../actions/settings'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import * as eventsUou from '../bi/uou-bi-events-map'
import {DetailsPageProps} from '../components/app/interfaces'
import reducers from '../reducers'
import {getDemoEvent} from '../services/demo-event'
import {createDetailsPageFedopsLogger} from '../services/fedops'
import {renderSEOTags} from '../services/seo'
import {Actions, FedopsLogger, State, StoreExtraArgs} from '../types'
import {userEventsLogger} from '../user-events-logger'
import {Api} from '../utils/api'
import {getRouteParams, parseLocation} from '../utils/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'

const DSN = 'https://588ca41dab294885b034d58caffde32b@sentry.wixpress.com/310'

export const createDetailsPageController = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const {wixCodeApi} = controller
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const {onAppLoaded, onSSRPageReady, ...fedopsLogger} = createDetailsPageFedopsLogger(controller)
  const language = getLanguage(wixCodeApi)

  const translationsPromise = importResources(['page', 'demo-data'], language, staticsBaseUrl)
  const [store, pageUrl, translations] = await Promise.all([
    createStore(controller, fedopsLogger, experiments),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
    translationsPromise,
  ])
  const state = store.getState()
  await renderSEOTags(controller, state, pageUrl.url)
  const actions = exportedActions(store, onAppLoaded, monitor)

  const props: DetailsPageProps = {
    state,
    actions,
    isRTL: isRtlLanguage(language),
    staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
    pageUrl,
    translations,
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    onSSRPageReady()
  }

  handleWithoutRegistrationRedirect(state, store.dispatch)
  handleExternalRedirect(controller.wixCodeApi, state)
  addLocationListener(controller.wixCodeApi, store)
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

const createStore = async (
  controller: IWidgetControllerConfig,
  fedopsLogger: FedopsLogger,
  experiments: ExperimentsBag,
) => {
  const {wixCodeApi, compId, config, platformAPIs, appParams} = controller
  const serverApi = new Api(controller)

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {},
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})
  const [initialData, isPaidPlansInstalled] = await Promise.all([
    getInitialData(serverApi, controller, config.style.styleParams),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
  ])

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState, experiments},
    extraArguments: {serverApi, wixCodeApi, compId, platformAPIs, fedopsLogger},
    middleware: [...biMiddleware, userEventsLoggerMiddleware],
  })

  isPaidPlansInstalled && store.dispatch(setPaidPlansEnabled())

  await store.dispatch(setBaseEnvironment() as any)
  await store.dispatch(getMemberRsvp() as any)
  store.dispatch(addLoginListener() as any)

  watchInstance(controller, store.dispatch)
  subscribeToStateChanges(controller, store)

  return store
}

const getInitialData = async (
  serverApi: Api,
  controller: IWidgetControllerConfig,
  styleParams: IWixStyleParams,
): Promise<Partial<State>> => {
  const {wixCodeApi, appParams, config} = controller
  const navigation = parseLocation(wixCodeApi)
  const membersAreaEnabled = await isMembersEventsPageInstalled(wixCodeApi)
  const responsiveEditor = isResponsiveEditor(config)
  const [{event, component, siteSettings, demoEvents, tickets, policies}, order, member] = await Promise.all([
    serverApi.getData(navigation.slug, responsiveEditor),
    navigation.reservationId ? serverApi.getOrder() : null,
    membersAreaEnabled ? serverApi.getCurrentMember() : null,
  ])
  const instance = appParams.instance

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, navigation.slug, responsiveEditor) : event,
    siteSettings,
    demoEvents,
    tickets,
    navigation,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      id: component.id,
      settings: {
        ...component.config.settings,
        ...styleParams.numbers,
        ...styleParams.booleans,
      },
    },
    placedOrder: {order, error: false},
    membersAreaEnabled,
    currentMemberDetails: {
      id: member?.id || (wixCodeApi.user.currentUser?.loggedIn ? wixCodeApi.user.currentUser?.id : undefined),
      email: member?.loginEmail,
      firstName: member?.firstName,
      lastName: member?.lastName,
    },
    instance: {
      instance,
      ...decodeInstance(instance),
    },
    policies: {
      agreed: false,
      ...policies,
    },
  }
}

const exportedActions = (store: Store, appLoaded, monitor): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    setCouponCode,
    resetCouponCode,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    unselectTicket,
    getCheckoutOptions,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
    navigateToTicketsPicker,
    openCheckoutUnavailable,
    updateOrder,
    placeOrder,
    paymentMethodSelected,
    placeOrderButtonClicked,
    navigateToDetails,
    nextFormClicked,
    openTicketsDetailsModal,
    cancelReservation,
    applyCoupon,
    getPurchasedPlanList,
    applyPlan,
    handleRSVP,
    checkout,
    submitCheckoutStep,
    editStep,
    openTimeExpiredModal,
    openCantCompletePaymentModal,
    setValidPaymentAdded,
    navigate,
    setUseBuyerDetails,
    setTicketDetails,
    clearInvoice,
    navigateToOrder,
    navigateToChangeRsvp,
    setExpandedTicketIndex,
    handleNextStep,
    openMembersModal,
    updateSettings,
    publishComponentSettings,
    clearCheckout,
    getDiscount,
    applyMembershipDiscount,
    downloadTickets,
    shareEvent,
    toggleAgreePolicies,
    openPolicyModal,
    submitRsvpStep,
    handleNextRsvpStep,
    editRsvpStep,
    changeResponse,
    setFormFactor,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}

const addLocationListener = (wixCodeApi: IWixAPI, store: Store) =>
  wixCodeApi.location.onChange(() =>
    store.dispatch(navigate(parseLocation(wixCodeApi).route, getRouteParams(wixCodeApi)) as any),
  )

// TODO: FIX
// history.listen((location, action) => {
//   if (action === 'POP') {
//     this.props.cancelReservation(this.props.eventId, this.props.reservationId)
//     this.props.cancelOrder()
//   }
// })
